import { render, staticRenderFns } from "./Pamm.vue?vue&type=template&id=07a83519&scoped=true&"
import script from "./Pamm.vue?vue&type=script&lang=js&"
export * from "./Pamm.vue?vue&type=script&lang=js&"
import style0 from "./Pamm.vue?vue&type=style&index=0&id=07a83519&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a83519",
  null
  
)

export default component.exports