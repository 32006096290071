<template>
  <PageWrapper :tabs="tabs" :tabActive.sync="tabActive" class="trading_tool">
    <component :is="currentComponent" />
  </PageWrapper>
</template> 

<script>
import Mt4 from './components/Mt4.vue'
import Pamm from './components/Pamm.vue'
import Copytrade from './components/Copytrade.vue'
import Webtrader from './components/Webtrader.vue'

export default {
  name: 'tradingTool',
  components: {
    Mt4,
    Pamm,
    Copytrade,
    Webtrader
  },
  data() {
    return {
      tabs: [
        { name: 'tab1', label: 'MT4' },
        { name: 'tab2', label: 'Pamm' },
        { name: 'tab3', label: 'Copytrade' },
        { name: 'tab4', label: 'Webtrader' },
      ],
      tabActive: 'tab1',
    };
  },
  computed: {
    currentComponent() {
      if (this.tabActive === 'tab1') {
        return Mt4
      } else if (this.tabActive === 'tab2') {
        return Pamm
      } else if (this.tabActive === 'tab3') {
        return Copytrade
      } else {
        return Webtrader
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
